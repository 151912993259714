import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCardFeed, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import { BlockSerializer } from "../utils/BlockSerializer"
import Image from "gatsby-plugin-sanity-image"
import config from "./../utils/siteConfig"

/**
* Author page (/author/:slug)
*
* Loads all posts for the requested author incl. pagination.
*
*/
const Author = ({ data, location, pageContext }) => {
    const author = data.sanityAuthor

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="profile"
            />
            <Layout>
                <div className="container">
                    <header className="author-header">
                        <div className="author-header-content">
                            <h1>{author.name}</h1>
                            <BlockSerializer>{author._rawBio}</BlockSerializer>
                            <div className="author-header-meta">
                                {socialLink(author.website?.anchor, author.website?.url)}
                                {socialLink('Facebook', author.socialmedialinks?.facebook)}
                                {socialLink('Twitter', author.socialmedialinks?.twitter)}
                                {socialLink('LinkedIn', author.socialmedialinks?.linkedin)}
                                {socialLink('Youtube', author.socialmedialinks?.youtube)}
                                {socialLink('Instagram', author.socialmedialinks?.instagram)}
                                {socialLink('Pinterest', author.socialmedialinks?.pinterest)}
                            </div>
                        </div>
                        <div className="author-header-image">
                            {author.image && <Image {...author.image} alt={author.name} width={config.shareSquareImageSize} height={config.shareSquareImageSize} />}
                        </div>
                    </header>
                    <PostCardFeed data={data} />
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

const socialLink = (anchor, url) => {
    return url && <a className="author-header-item" href={url} target="_blank" rel="noopener noreferrer">{anchor}</a>
} 

Author.propTypes = {
    data: PropTypes.shape({
        sanityAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            //coverImage: PropTypes.string,
            image: PropTypes.object,
            website: PropTypes.object,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allSanityPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Author

export const pageQuery = graphql`
    query AuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
        sanityAuthor(slugWithRedirectValidation: { slug: {current: {eq: $slug}}}) {
            ...SanityAuthorFields
        }
        allSanityPost(
            sort: { order: DESC, fields: [publishedAt] },
            filter: {author: {slugWithRedirectValidation: { slug: {current: {eq: $slug}}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                    ...SanityPostFields
                }
            }
        }
        allSanitySiteSettings {
            edges {
                node {
                    authorSlug {
                        ...SlugWithRedirectValidationFields
                    }
                }
            }
        }
    }
`
